import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios'
import { ListGroup, Alert, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';



export default function Find() {
    // const navigate = useNavigate();

    const [billings, setBillings] = useState<{ No: string; Id: string; Date: string; Code: string; Description: string; Amount: number; Outstanding: number }[]>([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        fetchBillings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchBillings = async () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const Head = urlParams.get("TokenHead");
        const Tail = urlParams.get("TokenTail");
        const token = window.btoa(Head + "." + Tail)
        const headers = {
            'Authentication': `basic ${token}`
        }
        const valuedata = {
            "Action": {
                "Value": "Search"
            },
            "Input": {
                "Page": {
                    "Value": 1
                },
                "RowPerPage": {
                    "Value": 15
                }
            }
        }

        const url = 'https://api.gmc.gmclub.id/Billing/Search'
        await axios.post(url, valuedata, {
            headers: headers,
        }).then(({ data }) => {
            setLoading(false)
            // console.log('ini dari setBillings :', data.Output.Result);
            setBillings(data.Output.Result)
            localStorage.setItem('token', `basic ${token}`)
        }).catch(function (error) {
            console.log('ini errornya :', error); 
            // return setBillings(error)
        })
    }

    // var date = (new Date()).toISOString().substring(0, 10)
    var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
    });

    return (
        <div className="container">
            <Alert variant="success">
                <div className="d-flex justify-content-center">
                    <FontAwesomeIcon icon={faMoneyBillTransfer} /> &nbsp;
                    <h6 className="text-center text-primary text-uppercase">TRANSACTION</h6>
                </div>
            </Alert>
            {loading ? <div className="d-flex justify-content-center text-success">
                <Spinner animation="border" variant="success" /> &nbsp; Please Wait...
            </div>
                :
                <ListGroup>
                    {
                        billings.length > 0 && (
                            billings.map((row, key) => (
                                <Link
                                    key={key}
                                    to={`/billing/show/${row.Id}`}
                                    // eslint-disable-next-line eqeqeq
                                    className={`${row.Outstanding != 0 ? 'list-group-item list-group-item-action list-group-item-info' :
                                    'list-group-item list-group-item-action list-group-item-light'}`}>
                                    <div className="d-flex w-100 justify-content-between">
                                        <b className="mb-1">{row.Code}</b>
                                        <small>{row.Date}</small>
                                    </div>
                                    <p className="mb-1 text-justify">{row.Description}</p>
                                    <p className="mb-1 text-end">Invoice : &nbsp;<b>{formatter.format(row.Amount)}</b></p>
                                    <p className="mb-1 text-end">Oustanding : &nbsp;<b>{formatter.format(row.Outstanding)}</b></p>
                                    {/* <small>Transaksi Berhasil</small> */}
                                </Link>
                            ))
                        )
                    }
                </ListGroup> 
            } 
        </div>
    )
}