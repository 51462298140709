/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
// import Swal from 'sweetalert2';
import { Alert, Button, Card, Col, Modal, Row, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { logoLivin } from "../../assets"

export default function Show() {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    // const location = useLocation();
    const { id } = useParams()
    // var date = (new Date()).toISOString().substring(0, 10)
    const [billing, setBilling] = useState<{
        Code: { Value?: string };
        InternalRef: { Value?: string };
        Currency: { Value?: string };
        Rate: { Value?: string };
        Date: { Value?: string };
        Description: { Value?: string };
        DiscountPercent: { Value?: string }
        Outstanding: { Value?: any }
    }>();
    const [billingDetail, setBillingDetail] = useState<{
        Result:
        [{
            No: number;
            PlayerOrProduct: string;
            Item: string;
            Price: any;
            Disc: any;
            Qty: any;
            Total: any;
            Description: string;
        }];
        SubTotal: any;
        ServiceCharge: any;
        PB1: any;
        PPN: any;
        GrandTotal: any;
    }>();

    const token = localStorage.getItem('token')
    useEffect(() => {
        if (token) {
            setLoading(true)
            fetchBilling()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const fetchBilling = async () => {

        const headers = {
            'Authentication': `${token}`
        }
        const valuedata = {}

        let url = `https://api.gmc.gmclub.id/Billing/${id}`
        let json = {}

        await axios.post(url, valuedata, {
            headers: headers
        }).then(({ data }) => {
            // console.log('data then :', data);
            setBilling(data.Input)
        })

        url = `https://api.gmc.gmclub.id/Billing/${id}/Detail`
        json = {
            "Action": {
                "Value": "Search"
            }
        }

        await axios.post(url, json, {
            headers: headers
        }).then(({ data }) => {
            // console.log(data.Output)
            setLoading(false)
            setBillingDetail(data.Output)
        })
    }

    var formatter = new Intl.NumberFormat('id-ID');

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <div className="container">
            <Card>
                <Card.Header className="d-flex w-100 justify-content-between ps-0">
                    <Link to="#" onClick={() => navigate(-1)} className="pt-2 m-0 btn btn-link float-left">
                        <FontAwesomeIcon className="p-0 m-0 bi d-block mx-auto" icon={solid('chevron-left')} />
                    </Link>
                    <h4 className="pt-1 text-success">Suvarna Jakarta Golf Club</h4>
                </Card.Header>
                <Card.Body>
                    {loading ? <div className="d-flex justify-content-center text-success">
                        <Spinner animation="border" variant="success" /> &nbsp; Please Wait...
                    </div>
                        :
                        <><p className="text-center text-success mb-0"><b>Invoice: {id} {billing?.Code?.Value}</b></p>
                            <p className="text-center text-success mb-0"><b>Date: {billing?.Date?.Value}</b></p>
                            <p className="text-center">Currency: {billing?.Currency?.Value}, Rate: {billing?.Rate?.Value}</p>
                            <dl className="row">
                                <dt className="col-sm-3">Customer</dt>
                                <dd className="col-sm-9">UG Un-Accompanied Guest</dd>
                                <dt className="col-sm-3">Customer Name</dt>
                                <dd className="col-sm-9">{billingDetail?.Result[0].PlayerOrProduct.split(" - ")[1]}</dd>
                                <dt className="col-sm-3">Player</dt>
                                <dd className="col-sm-9"></dd>
                                <dt className="col-sm-3">Description</dt>
                                <dd className="col-sm-9">{billing?.Description?.Value}</dd>
                                <dt className="col-sm-3">Discount %</dt>
                                <dd className="col-sm-9">{billing?.DiscountPercent?.Value}</dd>
                            </dl>
                            <b>Detail Transaction</b>
                            <dl className="row mt-2">
                                {billingDetail?.Result.map((row, key) => (
                                    <div key={key}>
                                        <dt className="col-sm-3">{row.Item}</dt>
                                        <dd className="col-sm-9 pb-0 mb-0">
                                            <div className="d-flex w-100 justify-content-between">
                                                <span>{formatter.format(row.Price)} x {row.Qty}</span>
                                                <span>{formatter.format(row.Total)}</span>
                                            </div>
                                        </dd>
                                    </div>
                                ))}
                            </dl><hr />
                            <Row>
                                <Col>
                                    {billing?.Outstanding?.Value != 0 ?
                                        <div className="row justify-content-start">
                                            <div className="col-sm-6 pb-0 mb-0">
                                                <Link to="#" onClick={() => handleShow()}>
                                                    {/* <img onClick={handleShow} src={logoLivin} alt="logoLivin" /> */}
                                                    <img onClick={handleShow} src={logoLivin} alt="logoLivin" />
                                                </Link>
                                            </div>
                                        </div>
                                        :
                                        <div className="row justify-content-start">
                                            <div className="col-sm-6 pb-0 mb-0">
                                                <Alert variant="info" className="text-warning text-uppercase text-center fw-bolder">You don't have any Unpaid Payments</Alert>
                                            </div>
                                        </div>
                                    }
                                </Col>
                                <Col>
                                    <div className="row justify-content-end">
                                        <div className="col-sm-6 pb-0 mb-0">
                                            <div className="d-flex w-100 justify-content-between">
                                                <span>Subtotal</span>
                                                <span>{formatter.format(billingDetail?.SubTotal)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-end">
                                        <div className="col-sm-6 pb-0 mb-0">
                                            <div className="d-flex w-100 justify-content-between">
                                                <span>Service Charge</span>
                                                <span>{formatter.format(billingDetail?.ServiceCharge)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-end">
                                        <div className="col-sm-6 pb-0 mb-0">
                                            <div className="d-flex w-100 justify-content-between">
                                                <span>PB1</span>
                                                <span>{formatter.format(billingDetail?.PB1)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-end">
                                        <div className="col-sm-6 pb-0 mb-0">
                                            <div className="d-flex w-100 justify-content-between">
                                                <span>PPN</span>
                                                <span>{formatter.format(billingDetail?.PPN)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-end">
                                        <div className="col-sm-6">
                                            <div className="d-flex w-100 justify-content-between">
                                                <span>Grand Total</span>
                                                <span><b>{formatter.format(billingDetail?.GrandTotal)}</b></span>
                                            </div>
                                            <div className="d-flex w-100 justify-content-between">
                                                <span className="text-danger">Outstanding</span>
                                                <span className="text-danger fw-bolder"><b>{formatter.format(billing?.Outstanding?.Value)}</b></span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    }
                </Card.Body>
            </Card>
            <Modal
                show={show}
                size="sm"
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-success">Invoice: {id} {billing?.Code?.Value}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center text-dark fw-bold text-bg-info">
                        Please Verify Before Payment 
                    </div> <br/>
                    <div className="d-flex w-100 justify-content-between">
                        <span>Subtotal</span>
                        <span>{formatter.format(billingDetail?.SubTotal)}</span>
                    </div>
                    <div className="d-flex w-100 justify-content-between">
                        <span>Service Charge</span>
                        <span>{formatter.format(billingDetail?.ServiceCharge)}</span>
                    </div>
                    <div className="d-flex w-100 justify-content-between">
                        <span>PB1</span>
                        <span>{formatter.format(billingDetail?.PB1)}</span>
                    </div>
                    <div className="d-flex w-100 justify-content-between">
                        <span>PPN</span>
                        <span>{formatter.format(billingDetail?.PPN)}</span>
                    </div>
                    <div className="d-flex w-100 justify-content-between">
                        <span>Grand Total</span>
                        <span><b>{formatter.format(billingDetail?.GrandTotal)}</b></span>
                    </div>
                    <div className="d-flex w-100 justify-content-between">
                        <span className="text-danger">Outstanding</span>
                        <span className="text-danger fw-bolder"><b>{formatter.format(billing?.Outstanding?.Value)}</b></span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary">Payment</Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}