import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
// import axios from 'axios'
// import Swal from 'sweetalert2';
import { Row, Col, Card, FloatingLabel, Form, Button, ToggleButton, Image, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import logo from '../../logo.svg'

export default function Find() {
    const navigate = useNavigate();

    // const [title, setTitle] = useState("")
    // const [description, setDescription] = useState("")
    // const [validationError,setValidationError] = useState({})

    const listClub = async (e: any) => {
        e.preventDefault();
        navigate("/club")

        // const formData = new FormData()

        // formData.append('title', title)
        // formData.append('description', description)

        // await axios.post(`http://localhost:8000/api/products`, formData).then(({data})=>{
        //   Swal.fire({
        //     icon:"success",
        //     text:data.message
        //   })
        //   navigate("/")
        // }).catch(({response})=>{
        //   if(response.status===422){
        //     setValidationError(response.data.errors)
        //   }else{
        //     Swal.fire({
        //       text:response.data.message,
        //       icon:"error"
        //     })
        //   }
        // })
    }

    var date = (new Date()).toISOString().substring(0, 10)
    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <Card>
                        <Card.Body>
                            <div>
                                <Link to={"/club/find"} className="btn btn-primary mt-2">
                                    <FontAwesomeIcon icon={solid('magnifying-glass')} />
                                    &nbsp;Cari Club
                                </Link>
                            </div>
                            <div>
                                <Link to={"/billing"} className="btn btn-primary mt-2">
                                    <FontAwesomeIcon icon={solid('money-bill-trend-up')} />
                                    &nbsp;Billing
                                </Link>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    )
}