import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, FloatingLabel, Form, Button, ToggleButton, Image, Table } from 'react-bootstrap'
import axios from 'axios';
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'

export default function Menu() {

    return (
        <div className="container m-0 p-0">
            <Card>
                <Card.Header className="text-center text-white bg-primary pt-4">
                    <Image src="https://png.pngtree.com/png-vector/20190704/ourlarge/pngtree-businessman-user-avatar-free-vector-png-image_1538405.jpg" alt="profile" className="rounded-circle" />
                    <h3 className="py-2">Ini Adalah Nama Saya</h3>
                </Card.Header>
                <Card.Body>
                    <dl className="row">
                        <dt className="col-4">No. HP</dt>
                        <dd className="col-8">0812345678</dd>
                        <dt className="col-4">No. GMC</dt>
                        <dd className="col-8">GMC013</dd>
                        <dt className="col-4">No. ID</dt>
                        <dd className="col-8">23475899345783</dd>
                        <dt className="col-4">Address</dt>
                        <dd className="col-4">Yogyakarta</dd>
                    </dl>
                    <div className="d-grid gap-2">
                        <Button variant="primary" size="lg">
                            Edit Profile
                        </Button>
                        <Button variant="primary" size="lg">
                            Change Password
                        </Button>
                        <Button variant="primary" size="lg">
                            Inbox Message
                        </Button>
                        <Button variant="primary" size="lg">
                            Sign Out
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        </div >
    )
} 