import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, FloatingLabel, Form, Button, ToggleButton, Image, Table } from 'react-bootstrap'
import axios from 'axios';
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'

export default function Menu() {

    return (
        // <div className="card">
        //     <div className="card-body">
        <div>
            <ul className="list-unstyled mb-0">
                <li className="p-2 border-bottom">
                    <a href="#!" className="d-flex justify-content-between">
                        <div className="d-flex flex-row">
                            <Image src="https://png.pngtree.com/png-vector/20190704/ourlarge/pngtree-businessman-user-avatar-free-vector-png-image_1538405.jpg" alt="avatar"
                                className="rounded-circle d-flex align-self-center me-3 shadow-1-strong" width="60" />
                            <div className="pt-1">
                                <p className="fw-bold mb-0">User Satu</p>
                                <p className="small text-muted">Pesan satu</p>
                            </div>
                        </div>
                        <div className="pt-1">
                            <p className="small text-muted mb-1">Just now</p>
                            <span className="badge bg-danger float-end">1</span>
                        </div>
                    </a>
                </li>
                <li className="p-2 border-bottom">
                    <a href="#!" className="d-flex justify-content-between">
                        <div className="d-flex flex-row">
                            <Image src="https://png.pngtree.com/png-vector/20190704/ourlarge/pngtree-businessman-user-avatar-free-vector-png-image_1538405.jpg" alt="avatar"
                                className="rounded-circle d-flex align-self-center me-3 shadow-1-strong" width="60" />
                            <div className="pt-1">
                                <p className="fw-bold mb-0">User 2</p>
                                <p className="small text-muted">Pesan dua</p>
                            </div>
                        </div>
                        <div className="pt-1">
                            <p className="small text-muted mb-1">5 mins ago</p>
                        </div>
                    </a>
                </li>
                <li className="p-2 border-bottom">
                    <a href="#!" className="d-flex justify-content-between">
                        <div className="d-flex flex-row">
                            <Image src="https://png.pngtree.com/png-vector/20190704/ourlarge/pngtree-businessman-user-avatar-free-vector-png-image_1538405.jpg" alt="avatar"
                                className="rounded-circle d-flex align-self-center me-3 shadow-1-strong" width="60" />
                            <div className="pt-1">
                                <p className="fw-bold mb-0">User Tiga</p>
                                <p className="small text-muted">Pesan tiga</p>
                            </div>
                        </div>
                        <div className="pt-1">
                            <p className="small text-muted mb-1">Yesterday</p>
                        </div>
                    </a>
                </li>
            </ul>

            <a href="#" className="btn-float bg-primary">
                <FontAwesomeIcon icon={solid('plus')} size="2x" className="mt-3" />
            </a>
        </div>

        //     </div>
        // </div>
    )
} 