// import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'
// import axios from 'axios'
// import Swal from 'sweetalert2';
import { Row, Col, Card, FloatingLabel, Form, Button, Image } from 'react-bootstrap'
import logo from '../../logo.svg'
import background from './/../../assets/images/bg.jpg'

export default function Find() {
    const navigate = useNavigate();

    // const [title, setTitle] = useState("")
    // const [description, setDescription] = useState("")
    // const [validationError,setValidationError] = useState({})

    const listClub = async (e: any) => {
        e.preventDefault();
        navigate("/club")

        // const formData = new FormData()

        // formData.append('title', title)
        // formData.append('description', description)

        // await axios.post(`http://localhost:8000/api/products`, formData).then(({data})=>{
        //   Swal.fire({
        //     icon:"success",
        //     text:data.message
        //   })
        //   navigate("/")
        // }).catch(({response})=>{
        //   if(response.status===422){
        //     setValidationError(response.data.errors)
        //   }else{
        //     Swal.fire({
        //       text:response.data.message,
        //       icon:"error"
        //     })
        //   }
        // })
    }

    var date = (new Date()).toISOString().substring(0, 10)
    return (
        <>
            <img className="bg-image" src="https://stagging1.askara-tech.com/Assets/Background.png" />
            <div className="bg-gradient-white"></div>
            <div className="container">
                <div className="text-center py-3"><Image src="https://stagging1.askara-tech.com/Assets/Logo.svg" /></div>
                <div className="row">
                    <div className="col-12">
                        <Card>
                            <Card.Body>
                                <Form onSubmit={listClub}>
                                    <Row className="g-2">
                                        <Col xs>
                                            <FloatingLabel controlId="" label="Tanggal">
                                                <Form.Control type="date" defaultValue={date} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col xs>
                                            <FloatingLabel
                                                controlId=""
                                                label="AM/PM"
                                            >
                                                <Form.Select aria-label="AM/PM">
                                                    <option value='am'>AM</option>
                                                    <option value='pm'>PM</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col xs>
                                            <FloatingLabel
                                                controlId=""
                                                label="Lokasi"
                                            >
                                                <Form.Select aria-label="AM/PM">
                                                    <option value='am'>Suvarna Jakarta Golf Club</option>
                                                    <option value='pm'>Test Golf Club</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <div className="mt-2 d-grid gap-2">
                                        <Button className="rounded-pill shadow-sm" variant="success" type="submit" size="lg">
                                            Cari
                                        </Button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    )
}