import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, FloatingLabel, Form, Button, ToggleButton, Image, Table } from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import axios from 'axios';
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'

export default function Menu() {

    return (
        <Tabs
            defaultActiveKey="green"
            id="fill-tab-example"
            className="mb-3"
            fill
        >
            <Tab eventKey="red" title="Red">
                <h1>Score Red</h1>
            </Tab>
            <Tab eventKey="green" title="Green">
                <h1>Score Green</h1>
            </Tab>
            <Tab eventKey="blue" title="Blue">
                <h1>Score Blue</h1>
            </Tab>
        </Tabs>
    )
} 