import React from 'react'
import Navbar from "react-bootstrap/Navbar"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import "bootstrap/dist/css/bootstrap.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import './App.css';
import background from './/assets/images/lake.jpg'

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"

// import EditProduct from "./components/product/edit.component"
// import ProductList from "./components/product/list.component"
// import CreateProduct from "./components/product/create.component"

import Club from "./view/club/list.component"
import ClubFind from "./view/club/find.component"
import Profile from "./view/profile/index"
import Landing from "./view/landing/index"
import Billing from "./view/billing/index"
import BillingShow from "./view/billing/show"
import Chat from "./view/chat/index"
import Score from "./view/score/index"


function MenuModal(props: any) {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      id="menu-modal"
      centered
    >
      <Modal.Body>
        <Row className="p-2">
          <Col className="text-center">
            <Button className="rounded-circle bg-dark" onClick={props.onHide}>
              <Link to={"/profile"} className="text-primary">
                <FontAwesomeIcon icon={solid('user')} className="" size="2x" /><br />
              </Link>
            </Button>
            Profile
          </Col>
          <Col className="text-center">
            <Button className="rounded-circle bg-dark" onClick={props.onHide}>
              <Link to={"/billing"} className="text-primary">
                <FontAwesomeIcon icon={solid('money-bill-trend-up')} className="" size="2x" /><br />
              </Link>
            </Button>
            Billing
          </Col>
          <Col className="text-center"></Col>
          <Col className="text-center"></Col>
          <Col className="text-center"></Col>
        </Row>
      </Modal.Body>
    </Modal >
  );
}

function App() {
  const [modalShow, setModalShow] = React.useState(false)
  return (
    <Router>
      {/* <Navbar bg="primary" className="fixed-bottom pb-0 m-2 shadow-sm rounded">
        <Container>
          <Link to={"/"} className="navbar-brand text-white">
            <FontAwesomeIcon className="bi d-block mx-auto" icon={solid('home')} />
            <small>Home</small>
          </Link>
          <Link to={"/chat"} className="navbar-brand text-white">
            <FontAwesomeIcon className="bi d-block mx-auto" icon={solid('comments')} />
            <small>Chat</small>
          </Link>
          <Link to="#" onClick={() => setModalShow(true)} className="navbar-brand text-white">
            <FontAwesomeIcon className="bi d-block mx-auto" icon={solid('bars')} />
            <small>Menu</small>
          </Link>
          <Link to={"/score"} className="navbar-brand text-white">
            <FontAwesomeIcon className="bi d-block mx-auto" icon={solid('clipboard-list')} />
            <small>Score</small>
          </Link>
          <Link to={"/"} className="navbar-brand text-white">
            <FontAwesomeIcon className="bi d-block mx-auto" icon={solid('right-from-bracket')} />
            <small>Logout</small>
          </Link>
        </Container>
      </Navbar> */}

      <MenuModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <Container>
        <Row className="mt-3 mb-5 pb-5">
          <Col md={12}>
            <Routes>
              {/* <Route path="/product/create" element={<CreateProduct />} />
            <Route path="/product/edit/:id" element={<EditProduct />} />
            <Route path='/product' element={<ProductList />} /> */}
              <Route path='/' element={<Billing />} />
              <Route path='/club/find' element={<ClubFind />} />
              <Route path='/club' element={<Club />} />
              <Route path='/profile' element={<Profile />} />
              <Route path='/landing' element={<Landing />} />
              <Route path='/billing' element={<Billing />} />
              <Route path='/billing/show/:id' element={<BillingShow />} />
              <Route path='/chat' element={<Chat />} />
              <Route path='/score' element={<Score />} />
            </Routes>
          </Col>
        </Row>
      </Container>
    </Router>
  );
}

export default App;
