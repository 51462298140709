import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2';
import { Row, Col, Card, FloatingLabel, Form, Button, ToggleButton, Image, Table } from 'react-bootstrap'
import logo from '../../logo.svg'

export default function Find() {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);

  return (
    <div className="container bg-white">
      <div className="text-center"><Image src="https://stagging1.askara-tech.com/Assets/Logo_SJGC.svg" /></div>
      <form>
        <Row className="g-2">
          <Col xs>
            <FloatingLabel controlId="" label="Tanggal">
              <Form.Control type="text" defaultValue={'30 Sep 2022'} disabled className="bg-transparent border-0" />
            </FloatingLabel>
          </Col>
          <Col xs>
            <FloatingLabel controlId="" label="AM/PM">
              <Form.Control type="text" defaultValue={'AM'} disabled className="bg-transparent border-0" />
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col xs>
            <FloatingLabel controlId="" label="Lokasi">
              <Form.Control type="text" defaultValue={'Suvarna Jakarta Golf Club'} disabled className="bg-transparent border-0" />
            </FloatingLabel>
          </Col>
        </Row>
        <b className="ms-2 mt-2 float-start">Available Tee Time</b>
        <Button variant="success" type="submit" className="float-end mb-2"
          onClick={(e) => {
            e.preventDefault()
            navigate("/club/find")
          }}>
          Change
        </Button>
      </form>
      <Table size="sm" className="">
        <thead>
          <tr>
            <th className="text-success text-center">Green Course</th>
            <th className="text-danger text-center">Red Course</th>
            <th className="text-primary text-center">Blue Course</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-center">
              <ToggleButton
                className="mb-2"
                id="toggle-check-1"
                type="checkbox"
                size="sm"
                variant="outline-primary"
                value="1"
              // checked={checked}
              // onChange={(e) => setChecked(e.currentTarget.checked)}
              >
                00:00
              </ToggleButton>
            </td>
            <td className="text-center">
              <ToggleButton
                className="mb-2"
                id="toggle-check-2"
                type="checkbox"
                size="sm"
                variant="outline-primary"
                value="1"
              // checked={checked}
              // onChange={(e) => setChecked(e.currentTarget.checked)}
              >
                00:00
              </ToggleButton>
            </td>
            <td className="text-center">
              <ToggleButton
                className="mb-2"
                id="toggle-check-3"
                type="checkbox"
                size="sm"
                variant="outline-primary"
                value="1"
              // checked={checked}
              // onChange={(e) => setChecked(e.currentTarget.checked)}
              >
                00:00
              </ToggleButton>
            </td>
          </tr>
          <tr>
            <td className="text-center">
              <ToggleButton
                className="mb-2"
                id="toggle-check-4"
                type="checkbox"
                size="sm"
                variant="outline-primary"
                value="1"
              // checked={checked}
              // onChange={(e) => setChecked(e.currentTarget.checked)}
              >
                00:00
              </ToggleButton>
            </td>
            <td className="text-center">
              <ToggleButton
                className="mb-2"
                id="toggle-check-5"
                type="checkbox"
                size="sm"
                variant="outline-primary"
                value="1"
              // checked={checked}
              // onChange={(e) => setChecked(e.currentTarget.checked)}
              >
                00:00
              </ToggleButton>
            </td>
            <td className="text-center">
              <ToggleButton
                className="mb-2"
                id="toggle-check-6"
                type="checkbox"
                size="sm"
                variant="outline-primary"
                value="1"
              // checked={checked}
              // onChange={(e) => setChecked(e.currentTarget.checked)}
              >
                00:00
              </ToggleButton>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}